export default {
邮箱: "Email",
 手机号码: "Số điện thoại",
 密码: "Mật khẩu",
 登录: "Đăng nhập",
 还没账户: "Chưa có tài khoản",
 忘记密码: "Quên mật khẩu",
 立即注册: "Đăng kí ngay",
 TNFT账户登录: "Tài khoản đăng nhập TNFT",
 请输入邮箱: "Vui lòng nhập email",
 邮箱格式错误: "Quy cách Email không đúng",
 请输入手机号码: "Vui lòng nhập số điện thoại",
 请输入密码: "Vui lòng nhập mật khẩu",
 请选择: "Vui lòng chọn",
 TNFT注册: "Đăng ký TNFT",
 验证码: "Mã xác minh",
 推荐码: "Mã giới thiệu",
 确定: "Xác nhận",
 获取验证码: "Nhận mã xác minh",
 邮箱格式错误: "quy cách Email không đúng",
 请输入手机号码: "vui lòng nhập số điện thoại",
 请输入邮箱: "vui lòng nhập email",
 请输入正确的邮箱: "vui lòng nhập email chính xác",
 请输入密码: "vui lòng nhập mật khẩu",
 登录密码只能为6至20位数字或字母: "mật khẩu đăng nhập chỉ có thể từ 6 đến 20 chữhoặc số",
 重置密码: "Đặt lại mật khẩu",
 新密码: "Mật khẩu mới",
 预售中: "Đang đặt trước",
 交易列表: "Danh sách giao dịch",
 收藏品: "Bộ sưu tập",
 限量: "Số lượng giới hạn",
 份: "Phần",
 用户指南: "Hướng dẫn người dùng",
 什么是NFT: "Thế nào là NFT",
 首页: "Trang chủ",
 发现: "Phát hiện",
 藏宝室: "Đặt hàng",
 我的: "Của tôi",
 搜索您要的精彩: "Tìm thứ bạn cần",
 我的收藏: "Bộ sưu tập của tôi",
 环球猎手顶级NFT艺术展览尽在TNFT: "Triển lãm Nghệ thuật NFT hàng đầu của Global Hunter tại TNFT",
 成为发行方: "Phát hành NFT",
 成为发行方您可以在TNFT发行NFT: "Trở thành người phát hành bạn có thể phát hành NFT tại TNFT",
 升级商家: "Nâng cấp thương gia",
 成为商家您可以挂交易订单: "Trở thành thương gia bạn có thể đặt lệnh giao dịch ",
 公告头条: "Tiêu đề Thông báo",
 关于发行: "Nói về phát hành",
 拍卖中心: "Trung tâm bán đấu giá",
 铸造中心: "Trung tâm mục lục",
 发行机构: "Đơn Vị phát hành",
 创作NFT: "Sáng tác NFT",
 "加入NFT发行方，您可以创建属于自己的NFT": " Tham gia người phát hành NFT, bạn có thể thành lập NFT thuộc về bản thân",
 入驻NFT发行: "gia nhập vào phát hành NFT ",
 开始: "Bắt đầu",
 订单记录: "Lịch sử đơn hàng",
 全部: "tất cả",
 进行中: "đang tiến hành",
 已失效: "Đã thất bại",
 已完成: "đã thành công",
 预售倒计时: "đếm ngược đặt trước",
 支付倒计时: "đếm ngược thanh toán",
 份额: "số định mức",
 需支付: "cần thanh toán",
 立即支付: " thanh toán ngay",
 立即查看: "kiểm tra ngay",
 订单号: "số đơn hàng",
 升级商家: "Nâng cấp thương gia",
 成为发行方: " Trở thành người phát hành",
 "请输入USDT(TRC20)地址": "vui lòng nhập địa chỉ USDT(TRC20) ",
 请输入USDT地址: "vui lòng nhập địa chỉ USDT ",
 确认: "Xác nhận",
 取消: "Hủy bỏ",
 信息: "Thông tin",
 我的收藏: "Bộ sưu tập của tôi",
 我的NFT: " NFT của tôi",
 余额: "số dư",
 充值: "nạp tiền",
 提现: "rút tiền",
 我的资产: "Tài sản của tôi",
 团队列表: "Danh sách nhóm",
 邀请会员: "Mời thành viên",
 账户绑定: "Liên kết tài khoản",
 账户明细: "Chi tiết tài khoản",
 联系客服: "Liên hệ chăm sóc khách hàng",
 退出登录: "Đăng xuất",
 编辑资料: "Chỉnh sửa thông tin",
 头像: "ảnh đại diện",
 昵称: "Biệt danh",
 账号: "Tài khoản",
 推荐码: "Mã giới thiệu",
 安全设置: "An toàn cài đặt",
 交易密码: "Mật khẩu giao dịch",
 "昵称长度必须为5-15个字符": "độ dài biệt danh cần 5-15 chữ số",
 提交: "Giao nộp",
 登录密码: "Mật khẩu đăng nhập",
 新交易密码: "Mật khẩu giao dịch mới",
 消息列表: "Danh sách thông tin",
 暂无信息: "Không có thông tin",
 类别: "Loại",
 点击选择: "ấn để chọn",
 地址: "Địa chỉ",
 钱包充值: "Nạp ví tiền",
 输入金额: "Nhập số tiền",
 上传凭证: "tải lên chứng từ",
 复制成功: "sao chép thành công",
 金额错误: "số tiền sai",
 请上传凭证: "vui lòng tải lên chứng từ",
 充值记录: "lịch sử nạp tiền",
 没有更多了: "không có thêm nữa",
 审核通过: "thông qua xét duyệt",
 审核中: "đang xét duyệt",
 驳回: "không nhận",
 提现: "rút tiền",
 开户姓名: "họ tên tài khoản",
 提现金额: "số tiền rút",
 输入金额: "nhập số tiền",
 最大可提金额: "số tiền tối đa có thể rút",
 提现记录: "Lịch sử rút tiền",
 团队列表: "Danh sách nhóm",
 注册时间: "thời gian đăng ký",
 我的资产: "tài sản của tôi",
 资产列表: "danh sách tài sản",
 Swap货币兑换: "thu đổi ngoại tệ Swap",
 货币兑换: "thu đổi ngoại tệ",
 兑换金额: "số tiền thu đổi",
 您最多可兑换: "bạn có thể thu đổi tối đa",
 余额不足: "số dư không đủ",
 立即兑换: "thu đổi ngay",
 长按图片保存: "Nhấn và giữ ảnh để lưu",
 分享推广: "Chia sẻ quảng bá",
 保存二维码: "Lưu mã QR",
 扫一扫来玩TNFT赚取收益: "Quét mã để chơi TNFT kiếm lợi nhuận",
 我的邀请码: "Mã giới thiệu của bạn",
 立即分享给好友: "Chia sẻ với bạn bè ngay",
 复制链接: "Sao chép liên kết",
 复制邀请码: "Sao chép mã mời",
 请输入开户姓名: "Vui lòng nhập họ tên tài khoản",
 账户余额: "số dư tài khoản",
 收入: "thu nhập",
 支出: "thanh toán",
 联系客服: "Liên hệ chăm sóc khách hàng",
 在线客服: "Chăm sóc khách hàng online",
 客服1: "chăm sóc khách hàng 1",
 客服2: "chăm sóc khách hàng 2",
 打开: "mở",
 我的收藏: "Mục yêu thích của tôi",
 申请商家: "đăng ký thương gia",
 确认入驻商家: "xác nhận gia nhập thương gia",
 我同意: "tôi đồng ý",
 升级请联系客服: "nâng cấp vui lòng liên hệ chăm sóc khách hàng",
 NFT搜索: "Tìm kiếm NFT",
 请输入搜索关键词: "Vui lòng nhập từ khóa tìm kiếm",
 最新: "mới nhất",
 最热: "hot nhất",
 创作NFT: "sáng tác NFT",
 NFT文件: "tệp NFT",
 NFT名称: "tên NFT",
 简介: "hồ sơ giới thiệu",
 请输入简介: "vui lòng nhập hồ sơ",
 合集: "bộ sưu tập",
 点击选择主链: "ấn để chọn liên kết chính",
 立即创建: "tạo ngay",
 申请须知: "Hướng dẫn cần biết",
 "申请NFT发行方需联系TNFT客服，申请成功，即可创建属于您自己的NFT作品!": "Để đăng ký người phát hành NFT cần liên hệ với chăm sóc khách hàng của TNFT, đăng ký thành công , bạn có thể tạo tác phẩm NFT của riêng mình! ",
 历史发行: " Lịch sử phát hành",
 藏品类目: "Loại mục sưu tầm",
 盲盒类目: "Loại hộp ngẫu nhiên",
 支付详情: "Chi tiết thanh toán",
 请输入支付密码: "vui lòng nhập mật khẩu thanh toán",
 预售倒计时: "đếm ngược đặt trước",
 支付倒计时: "đếm ngược thanh toán",
 订单号: "Mã đơn hàng",
 购买类型: "Loại hình mua",
 下单时间: "Thời gian xuống đơn",
 预售订单: "Đơn hàng đặt trước",
 支付时间: "Thời gian thanh toán",
 等待支付: "Đợi để thanh toán",
 已失效: "Đã hết hiệu lực",
 合计: "Tổng cộng",
 立即支付: "Thanh toán ngay",
 NFT详情: "Chi tiết NFT",
 NFT信息: "Thông tin NFT",
 预售价格: "giá đặt trước",
 购买类型: "Loại hình mua",
 预售订单: "Đơn đặt trước",
 开始时间: "Thời gian bắt đầu",
 发行方信息: "Thông tin người phát hành",
 作品特性: "Đặc tính của tác phẩm",
 合约地址: "Địa chỉ hợp đồng",
 作品描述: "Mô tả tác phẩm",
 买入: "Mua vào",
 卖出: "Bán ra ",
 立即下单: "Đặt hàng",
 持有数量: "Số lượng đang có",
 我要卖: "Tôi muốn bán",
 升级商家: "Nâng cấp thương gia ",
 请输入卖出份数: "vui lòng nhập số định mức bán ra",
 卖出NFT: "bán ra NFT",
 获得金额: "nhận được số tiền",
 出售: "bán ra",
 挂单数量: "số lượng đơn treo",
 拥有者: "người sở hữu",
 最少卖出: "bán ra tối thiểu",
 我要买: "Tôi muốn mua",
 买入NFT: "mua vào NFT",
 请输入买入份数: "vui lòng nhập số định mức mua vào",
 需支付: "cần thanh toán",
 购买: "mua",
 最少买入: "mua vào ít nhất",
 每份: "mỗi phần",
 确认下单: "xác nhận xuống đơn",
 "预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "Trong quá trình đơn đặt trước khi xuống đơn xong vui lòng đợi đơn đặt trước kết thúc. Sau khi đơn đặt trước kết thúc, bạn cần thanh toán số tiền đặt hàng trong vòng 24h ! ",
 暂未对普通会员开放: "Tạm thời chưa mở đối với thành viên phổ thông",
	 	 限购数量: "Giới hạn số lượng",
			 数量: "Số lượng",
			 剩余: "Còn lại",
			 发布方信息: "Thông tin nhà phát hành",
			 挂单: "Treo đơn",
			 交易订单: " Đơn hàng giao dịch",
			 当前持有: "Hiện đang nắm giữ",
	 	 	 NFT总份数: "Tổng số NFT",
			 选择买入或卖出: "Chọn mua hoặc bán",
			 请输入挂单价格: "Vui lòng nhập giá lệnh chờ",
			 请输入数量: "Vui lòng nhập số lượng",
			 最低: "Thấp nhất",
			 请输入最低份数: "Vui lòng nhập số lượng tối thiểu",
			 最高: "Cao nhất",
			 请输入最高份数: "Vui lòng nhập số lượng tối đa",
			 交易通知邮箱: "Hộp thư thông báo giao dịch",
			 请输入邮箱: "Vui lòng nhập email của bạn",
			 类型: "Kiểu",
			 价格: "Giá",
			 立即提交: "Xác nhận ngay bây giờ",
			 撤销: "Thu hồi",
			 已撤销: "Đã thu hồi",
			 已完成: " Đã hoàn thành",
			 支付金额: "Số tiền thanh toán",
			 地址: "Địa chỉ",
			 凭证: "Đằng chứng ",
			 买入订单: "Lệnh mua",
			 卖出订单: "Lệnh bán",
			 支付货币: "Đồng tiền thanh toán",
			 状态: "Tình trạng",
			 确认下单: "Xác nhận đơn hàng",
			 '预售进行中,下单完成后请6小时内支付给商家!': "Chương trình bán trước đang diễn ra, vui lòng thanh toán cho người bán trong vòng 6 giờ sau khi đặt hàng!",
			 参数错误: "Lỗi tham số",
			 该操作需要申请为TNFT商家: "Thao tác này cần được áp dụng với tư cách là người bán TNFT",
			 碎片化顶级NFT艺术展览尽在TNFT: "Các triển lãm nghệ thuật NFT hàng đầu bị phân mảnh đều có ở TNFT",
	 	 	 NFT碎片: "Các mảnh NFT",
			 将您持有的NFT分布成碎片: "Phân phối NFT bạn nắm giữ thành các phân đoạn",
			 成为商家: "Trở thành thương gia",
			 成为商家您可以将您持有的NFT拆分: "Trở thành thương gia Bạn có thể chia NFT mà bạn nắm giữ",
			 入驻TNFT商家: "Định cư tại các thương gia TNFT",
			 申请须知: "Hướng dẫn cần biết",
			 请提交区块链应用合法执照或者证件: "Vui lòng gửi giấy phép hợp pháp hoặc chứng chỉ ứng dụng blockchain",
			 开始: "Bắt đầu",
			 关于NFT: "Giới thiệu về NFT",
			 创作中心: "Trung tâm sáng tạo",
			 合成中心: "Trung tâm tổng hợp",
	 	 	 TNFT商家: "Thương gia",
	 	 	 TNFT碎片: "Phân mảnh",
			 碎片: "Mảnh vỡ",
			 版税: "Tiền bản quyền",	 
			 版税兑换: "Trao đổi",
			 转移NFT: "Chuyển NFT",
			 转入NFT: "Chuyển sang NFT",
			 版税明细: "Chi tiết tiền bản quyền",
			 联系邮箱: "Địa chỉ email liên lạc",
			 客服邮箱: "Email dịch vụ khách hàng",
			 申请成为商家: "Đăng ký làm thương gia",	 
			 公司名称: "Tên công ty",	 
			 请输入公司: "Vui lòng nhập công ty",
			 国家: "Quốc gia",	 
			 请输入国家: "vui lòng nhập quốc gia",	 
			 营业执照: "giấy phép kinh doanh",	 
			 法人证件正面: "Mặt trước giấy chứng nhận pháp nhân",	 
			 法人证件反面: "Mặt sau giấy chứng nhận pháp nhân",	 
			 请等待审核: "Vui lòng chờ xem xét",	
			 请输入正确参数: "Hãy nhập đúng thông số",	 
			 请上传完整图片: "Vui lòng tải lên hình ảnh đầy đủ",	
			 申请商家须知: "Thông báo đăng ký dành cho thương nhân",	 
			 请提交区块链应用合法执照或者证件: "Vui lòng gửi giấy phép hợp pháp hoặc chứng nhận của ứng dụng blockchain",
			 我的碎片: "Mảnh vỡ của tôi",
			 持有: "Giữ",
			 合成: "Kết hợp",
			 碎片数量不足: "Không đủ số lượng mảnh vỡ",
			 合成碎片: "Kết hợp Mảnh",
			 '合成碎片不可取消,是否确认合成': "Việc kết hợp các mảnh không thể bị hủy bỏ, có xác nhận Kết hợp hay không",
			 兑换NFT: "Đổi NFT",
			 请输入兑换份数: " Vui lòng nhập số lượng bản quy đổi ",
			 需支付版税: "Tiền bản quyền phải trả",
			 兑换: "Đổi",
			 点击选择NFT: "Nhấn chọn NFT",
			 请输入地址: "Vui lòng nhập địa chỉ",
			 转移记录: "Hồ sơ chuyển nhượng",
	 	 	 NFT转入记录: "Hồ sơ chuyển NFT",
	 	 	 NFT转入: "Chuyển NFT vào",
			 请输入名称: "vui lòng nhập tên",
			 '请输入特性,请使用逗号隔开': "Hãy nhập đặc điểm, vui lòng dùng dấu phẩy để phân cách",
			 请输入描述: "vui lòng nhập mô tả",
			 上传NFT文件: "Tải lên tệp NFT",
			 上传转入凭证: "Tải lên chứng chỉ chuyển nhượng",
			 请上传NFT文件: "Vui lòng tải lên tệp NFT",
			 请输入交易密码: "Vui lòng nhập mật khẩu giao dịch",
			 '暂未设置交易密码,请设置交易密码': "Chưa đặt mật khẩu giao dịch, vui lòng đặt mật khẩu giao dịch",
			 地址修改: "sửa địa chỉ",
			 '扫一扫来玩TNFT,交易0版税,推荐更有版税佣金': "Quét để chơi TNFT, 0 tiền bản quyền giao dịch và giới thiệu nhiều tiền hoa hồng bản quyền hơn",
			 忘记交易密码: "Quên mật khẩu giao dịch",
			 重复密码: "lặp lại mật khẩu",
			 新登录密码: "Mật khẩu đăng nhập mới",
			 重复登录密码: "Lặp lại mật khẩu đăng nhập",
			 查看该作者: "Xem tác giả",
			 待确认: "Được xác nhận",
				 立即确认: "Xác nhận ngay bây giờ",
				 请等待确认: "Vui lòng chờ xác nhận",
				 等待确认: "Chờ đợi sự xác nhận",
				 提示: "Gợi ý",
				 '您已确认无误收到款项，是否确认': "Bạn đã xác nhận rằng bạn đã nhận được khoản thanh toán chính xác. Bạn có muốn xác nhận không?",
				 提示: "gợi ý",
				 点击选择NFT文件: "Nhấn chọn tệp NFT",
				 请输入拆分份额: "Vui lòng nhập phần chia sẻ",
				 限购: "giới hạn mua hàng",
				 每人限购份额: "Giới hạn mua mỗi người",
				 币种: "Tiền tệ",
				 点击选择币种: "Bấm để chọn loại tiền tệ",
				 请输入价格: "Vui lòng nhập giá",
				 '倒计时(小时)': "Đếm ngược (giờ)",
				 点击选择倒计时: "Nhấn chọn đếm ngược",
	 	 	 	 TG聊天链接: "Liên kết trò chuyện TG",
				 请输入链接: "vui lòng nhập liên kết",
	 	 	 	 Line聊天链接: "Liên kết trò chuyện Line",
	 	 	 	 WhatsApp聊天链接: "Liên kết trò chuyện WhatsApp",
				 制作NFT碎片: "Tạo các mảnh NFT",
				 发布记录: "Bản ghi phát hành",
				 信息: "Thông tin",
				 您需要支付: "Bạn cần phải thanh toán ",
				 '未收到款?': "Chưa nhận được tiền?",
				 '您未收到款,客服将在2小时内处理您的订单,立即申请客服处理!': "Bạn chưa nhận được tiền, bộ phận chăm sóc khách hàng sẽ xử lý đơn hàng của bạn trong vòng 2h , hãy đăng ký để bộ phận chăm sóc khách hàng xử lí ngay lập tức!",
				 联系商家: "Liên hệ với người bán",
				 倒计时: "Đếm ngược",
				 卖出范围: "Phạm vi bán hàng",
				 买入范围: "Phạm vi mua",
				 该操作需要申请为TNFT商家: "Hành động này yêu cầu cần đăng kýlàm người bán TNFT",
				 '申请商家需要持有TNFT Cash pledge，详情请联系官方客服或邮箱': 'Đăng ký thương gia cần phải có TNFT Cash pledge. Để biết chi tiết, vui lòng liên hệ với dịch vụchăm sóc khách hàng chính thức hoặc email.',
				 质押TNFT: ' Thế chấp TNFT',
				 质押记录: 'Hồ sơ thế chấp',
				 赎回成功: 'Chuộc thành công',
				 进行中: 'Trong tiến trình',
				 审核中: 'Đang được xem xét',
				 质押失败: ' Thế chấp không thành công',
				 解除质押: 'Hủy thế chấp ',
				 奖励: 'Phần thưởng',
				 创建时间: 'Thời gian sáng lập',
				 结束时间: 'Thời gian kết thúc',
				 赎回: 'Chuộc',
	 	 	 	 TNFT名称: 'Tên TNFT',
				 预售价格: 'Giá bán trước',
				 质押数量: 'Số lượng Thế chấp',
				 请输入数量: 'Vui lòng nhập số lượng',
				 全部: 'Tất cả',
				 可用数量: 'Số lượng hiện có sẵn',
				 '碎片奖励(天)': 'Phần thưởng mảnh (ngày)',
				 备注: 'Ghi chú',
				 质押时间: 'Thời gian Thế chấp',
				 计息时间: 'Thời gian tính lãi',
				 赎回时间: 'Thời gian chuộc',
				 立即提交: 'lập tức gửi',
				 '时间(天)': 'Thời gian (ngày)',
				 质押确认: 'Xác nhận Thế chấp',
				 '质押您可以获得版税和手续费奖励，质押不可取消，请确认!': 'Bạn có thể nhận phần thưởng tiền bản quyền và phí xử lý bằng cách Thế chấp. Thế chấp không thểhủy, vui lòng xác nhận!',
				 持有: 'Giữ',
				 质押: 'Thế chấp',
				 价格趋势: 'Xu hướng giá',
				 持仓市值: 'Vị thế giá trị thị trường',
				 今日盈亏: 'Lãi lỗ hôm nay',
				 总盈亏:'Tổng lãi và lỗ',
				 持仓成本: ' Vị thế nắm giữ',
				 碎片数量: 'Số lượng mảnh',
				 质押碎片: 'Mảnh Thế chấp',
				 质押收入: 'Thu nhập Thế chấp',
				 名称: 'Tên',
				 实时价: 'Giá',
				 市值: 'Giá trị thị trường',
				 质押: 'Thế chấp ',
				 持仓: 'Vị thế',
				 我的持仓: 'Vị thế của tôi',
				 查看: 'Kiểm tra',
				 实名认证: 'Xác minh tên thật',
				 请等待实名审核: 'Hãy chờ đợi đánh giá tên thật',
				 确认: 'Xác nhận',
				 注意事项: 'Các mục cần chú ý',
				 '需持有TNFT Handling fee数量': 'Số lượng TNFT HTHandling fee cần được giữ',
				 上传身份证明: 'Tải lên Chứng nhận thân phận',
				 上传持有凭证: 'Tải lên bằng chứng',
				 历史: 'Lịch sử',
				 操作: 'Thao tác',
				 交易: ' buôn bán',
				 一键买入: 'Mua nhanh',
				 一键卖出: 'Bán nhanh',
				 最低回购价: 'Giá mua lại thấp nhất',
				 一键买卖: 'Giao dịch nhanh chóng',
				 买入价格: 'Giá mua',
				 预估回购价格: 'Giá mua lại ước tính',
				 回购价格比例: 'Tỷ lệ giá mua lại',
				 买入数量: 'Mua số lượng',
				 请输入买入数量: 'Vui lòng nhập số lượng mua hàng',
				 卖出数量: 'Bán số lượng',
				 请输入卖出数量: 'Vui lòng nhập số lượng bán',
				 回购价格: 'Giá mua lại',
				 数量不足: 'Không đủ số lượng',
				 信用分: 'Điểm tín dụng',
				 使用版税兑换: 'Sử dụng quy đổi tiền bản quyền',
				 '使用TNFT Royalties兑换': 'Đổi thưởng bằng tiền bản quyền TNFT',
				 '需支付版税/碎片': 'Tiền bản quyền/Phần phải trả',
				 'OTC只支持USDT(ERC)选项': 'OTC chỉ hỗ trợ tùy chọn USDT(ERC)',
				 进行中: 'Trong tiến trình',
				 请联系右上角与商家沟通交易: 'Vui lòng liên hệ góc trên bên phải để liên lạc với người bán để giao dịch',
				 订单已完成: 'Nhiệm vụ đã hoàn thành',
				 订单已取消: 'Lệnh hủy',
				 交易信息: 'Thông tin giao dịch',
				 单价: 'Đơn giá',
				 购买数量: 'Số lượng mua',
				 支付金额: 'Số tiền thanh toán',
				 商户名称: 'Tên doanh nghiệp',
				 订单ID: 'ID đơn hàng',
				 时间: 'Thời gian',
				 类型: 'Kiểu',
				 转入地址: 'Địa chỉ chuyển tới',
				 进行中: 'Trong tiến trình',
				 已取消: 'Đã hủy',
				 已完成: 'Hoàn thành',
				 OTC交易: 'Giao dịch OTC',
				 'TNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'Các giao dịch OTC của TNFT được kết nối với các nhà giao dịch trao đổi chính thống, mang đến cho bạn nhu cầu giao dịch tiền tệ hợp pháp thuận tiện.',
				OTC钱包: 'Ví OTC',
				卖出到OTC余额: 'Bán theo số dư OTC',
				OTC余额支付: 'Thanh toán bằng số dư OTC',
				OTC余额: 'Số dư OTC',
				C2C转账: 'Chuyển C2C',
				C2C需要您手动转账: 'C2C yêu cầu bạn chuyển tiền thủ công',
				确认支付: 'Xác nhận thanh toán',
				'OTC-Pay将使用您的OTC余额支付，请确认！': 'OTC-Pay sẽ sử dụng số dư OTC của bạn để thanh toán, vui lòng xác nhận!',
				OTC快捷交易: 'Giao dịch nhanh OTC',
				快捷交易能够让您以当前OTC市场中的最优价格成交: 'Giao dịch nhanh cho phép bạn hoàn tất giao dịch ở mức giá tốt nhất trên thị trường OTC hiện tại',
				购买: 'Mua',
				出售: 'Bán',
				我要支付: 'Tôi muốn trả tiền',
				我将收到: 'Tôi se nhận',
				参考价格: 'Giá đề xuất',
				如何使用OTC: 'Cách sử dụng OTC',
				OTC记录: 'Hồ sơ OTC',
				交易数量: 'Số lượng giao dịch',
				请先KYC再进行OTC操作: 'Vui lòng KYC trước khi tiếp tục các hoạt động OTC',
				上传身份证正面: 'Tải lên mặt trước CMND của bạn',
				上传身份证反面: 'Tải lên mặt sau của CMND',
				请上传身份证正面: 'Vui lòng tải lên mặt trước CMND của bạn',
				请上传身份证反面: 'Vui lòng tải lên mặt sau CMND của bạn',
				交易对象ID: 'ID đối tượng giao dịch',
				升级商家: 'Nâng cấp thương gia',
				请联系客服: 'Vui lòng liên hệ với dịch vụ khách hàng',
				确认入驻商家: 'Xác nhận thương nhân đã định cư ở',
				我同意: 'Tôi đồng ý',
				成为发行方: 'Trở thành nhà phát hành',
				成为发行方您可以发行属于您自己的NFT: 'Trở thành nhà phát hành và bạn có thể phát hành NFT của riêng mình',
				NFT发行方: 'Nhà phát hành NFT',
				'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': 'Để đăng ký trở thành nhà phát hành NFT, bạn cần phải có trình độ pháp lý tương ứng về blockchain. Để biết chi tiết, vui lòng liên hệ với dịch vụ khách hàng chính thức hoặc email.',
				'您的OTC钱包需持有': 'Ví OTC của bạn cần chứa',
				
				指数交易: 'Giao dịch chỉ số',
				未成交: 'Không có giao dịch',
				进行中: 'Trong tiến trình',
				已完成: 'Hoàn thành',
				强制平仓: 'Buộc thanh lý',
				撤单: 'Hủy đơn hàng',
				当前委托: 'Trật tự hiện tại',
				历史委托: 'Lệnh lịch sử',
				买入: 'Mua',
				卖出: 'Bán',
				倍杠杆: 'Đòn bẩy',
				保证金: 'lề',
				开仓价: 'Giá giao dịch',
				当前价: 'Giá hiện tại',
				持仓数量: 'số lượng vị trí',
				预计盈亏: 'Ước tính lãi lỗ',
				创建时间: 'Thời gian sáng tạo',
				止损价: 'Giá dừng lỗ',
				止盈价: 'Lấy giá lãi',
				手续费: 'Phí xử lý',
				添加保证金: 'Thêm ký quỹ',
				平仓: 'Đóng vị thế',
				立即下单: 'Đặt hàng ngay bây giờ',
				平仓价格: 'Giá đóng cửa',
				平仓时间: 'Thời gian hoàn thành',
				买涨: 'Mua lên',
				买跌: 'Mua hoặc bán',
				OTC余额: 'Số dư OTC',
				杠杆: 'Đòn bẩy',
				'NFT-GI预估数量': 'Số lượng ước tính NFT-GI',
				预估手续费: 'Phí xử lý ước tính',
				'买入/开多': 'Mua/Mở vị thế mua',
				'卖出/开空': 'Bán/Mở vị thế bán',
				设置价格: 'Đặt giá',
				请输入价格: 'Vui lòng nhập giá',
				请输入增加的保证金: 'Vui lòng nhập số tiền ký quỹ tăng thêm',
				请输入买入USDT金额: 'Vui lòng nhập số tiền cần mua USDT',
				最低增加保证金为10USDT: 'Ký quỹ bổ sung tối thiểu là 10 USDT',
				订单信息: 'Thông tin đặt hàng',
				'您是否立即撤单，撤单不可恢复，请确认!': 'Bạn muốn hủy đơn hàng ngay lập tức? Việc hủy đơn hàng không thể khôi phục được, vui lòng xác nhận!',
				'立即按照当前价格平仓，请确认!': 'Đóng vị thế ngay lập tức với mức giá hiện tại, vui lòng xác nhận!',
				'最小交易金额为100USDT': 'Số tiền giao dịch tối thiểu là 100USDT',
				盈亏: 'Lợi nhuận và thua lỗ',
				NFT指数: 'Chỉ số NFT',
				交易明细: 'Chi tiết giao dịch',
				请输入正确的登录密码: 'Vui lòng nhập đúng mật khẩu đăng nhập',
				两次密码不一致: 'Hai mật khẩu không nhất quán',
				请输入正确的交易密码: 'Vui lòng nhập đúng mật khẩu giao dịch',
				NFT借贷: 'Cho vay NFT',
				借贷: 'khoản vay',
				借贷入口: 'Lối vào mượn',
				还贷入口: 'Cổng hoàn trả khoản vay',
				借款申请: 'Đơn xin vay',
				借款金额: 'Số tiền vay',
				'请输入申请金额(USDT)': 'Vui lòng nhập số tiền đăng ký (USDT)',
				账户: 'Tài khoản',
				申请将获取您的账户信息: 'Áp dụng sẽ có được thông tin tài khoản của bạn',
				立即申请: 'Nộp đơn ngay lập tức',
				借款记录: 'Hồ sơ vay vốn',
				借款金额: 'Số tiền vay',
				创建时间: 'Thời gian',
				完成: 'Hoàn thành',
				进行中: 'Trong tiến trình',
				联系客服获取借款合同: 'Liên hệ bộ phận chăm sóc khách hàng để nhận hợp đồng vay',
				OTC还贷: 'Trả nợ khoản vay OTC',
				'该交易将为您购买的USDT,直接还款': 'Giao dịch này sẽ hoàn trả trực tiếp cho bạn số USDT bạn đã mua.',
				我要还款: 'Tôi muốn trả nợ',
}