export default {
	邮箱: "Email",
	手机号码: "Phone",
	密码: "Password",
	登录: "Log In",
	还没账户: "Don't have an account yet",
	忘记密码: "Forgot Password",
	立即注册: "Sign-up Now",
	TNFT账户登录: "TNFT Account Login",
	请输入邮箱: "Please insert your email address",
	邮箱格式错误: "Email Format Error",
	请输入手机号码: "Please enter the phone number",
	请输入密码: "Please enter password",
	请选择: "Please Select",
	TNFT注册: "TNFT Registration",
	验证码: "Verification Code",
	推荐码: "Referral Code",
	确定: "Confirm",
	获取验证码: "Verification Code",
	邮箱格式错误: "Email Format Error",
	请输入手机号码: "Please enter the phone number",
	请输入邮箱: "Please insert your email",
	请输入正确的邮箱: "Please enter a vaild email address",
	请输入密码: "Please Enter Password",
	登录密码只能为6至20位数字或字母: "The login password can only be 6 to 20 digits or letters",
	重置密码: "Reset Password",
	新密码: "New Password",
	预售中: "Pre-Sale",
	交易列表: "Transaction List",
	收藏品: "Collection",
	限量: "Limited",
	份: "",
	用户指南: "User Guide",
	什么是NFT: "What is NFT？",
	首页: "Home",
	发现: "Explore",
	藏宝室: "Transactions",
	我的: "Account",
	搜索您要的精彩: "Search",
	我的收藏: "My Collection",
	环球猎手顶级NFT艺术展览尽在TNFT: "Global Hunter's Top NFT Art Exhibition at TNFT",
	成为发行方: "Be the Creator",
	成为发行方您可以在TNFT发行NFT: "Become a creator and create your own NFTs on TNFT",
	升级商家: "Upgrade Business",
	成为商家您可以挂交易订单: "Become a merchant and distribute your NFT creations",
	公告头条: "Announcements",
	关于发行: "About Distribution",
	拍卖中心: "Auction Center",
	铸造中心: "Foundry Center",
	发行机构: "Creators",
	创作NFT: "Create NFTs",
	"加入NFT发行方，您可以创建属于自己的NFT": "Become a creator, and you can design your own NFT",
	入驻NFT发行: "Settled in NFT creation",
	开始: "Start",
	订单记录: "Order History",
	全部: "All",
	进行中: "In Progress",
	已失效: "Deactivated",
	已完成: "Completed",
	预售倒计时: "Pre-Sale Countdown",
	支付倒计时: "Payment Countdown",
	份额: "Quantity",
	需支付: "Payable",
	立即支付: "Pay Now",
	立即查看: "Check Now",
	订单号: "Order ID",
	升级商家: "Merchant",
	成为发行方: "Creators",
	"请输入USDT(TRC20)地址": "Please enter the USDT(TRC20) address",
	请输入USDT地址: "Please enter the USDT address",
	确认: "Confirm",
	取消: "Cancel",
	信息: "Information",
	我的收藏: "My Favorites",
	我的NFT: "My NFT",
	余额: "Balance",
	充值: "Top Up",
	提现: "Withdraw",
	我的资产: "My Assets",
	团队列表: "Team List",
	邀请会员: "Invite Members",
	账户绑定: "Wallet Binding",
	账户明细: "Account Details",
	联系客服: "Contact Customer Service",
	退出登录: "Sign Out",
	编辑资料: "Edit Information",
	头像: "Avatar",
	昵称: "Nickname",
	账号: "Account",
	推荐码: "Referral Code",
	安全设置: "Security Settings",
	交易密码: "Transaction Password",
	"昵称长度必须为5-15个字符": "Nickname must contain 5-15 characters",
	提交: "Submit",
	登录密码: "Login Password",
	新交易密码: "New Transaction Password",
	消息列表: "Message List",
	暂无信息: "No Information",
	类别: "Category",
	点击选择: "Click to Select",
	地址: "Address",
	钱包充值: "Wallet Recharge",
	输入金额: "Enter Amount",
	上传凭证: "Upload Certificate",
	复制成功: "Copy Successfully",
	金额错误: "Wrong Amount",
	请上传凭证: "Please upload your credentials",
	充值记录: "Recharge Record",
	没有更多了: "No More",
	审核通过: "Approved",
	审核中: "Under Review",
	驳回: "Rejected",
	提现: "Withdraw",
	开户姓名: "Name",
	提现金额: "Withdrawal Amount",
	输入金额: "Enter Amount",
	最大可提金额: "Maximum Amount",
	提现记录: "Withdrawals Record",
	团队列表: "Team List",
	注册时间: "Time",
	我的资产: "My Assets",
	资产列表: "Asset List",
	Swap货币兑换: "Swap currency exchange",
	货币兑换: "Currency Exchange",
	兑换金额: "Exchange Amount",
	您最多可兑换: "You can redeem up to",
	余额不足: "Insufficient Balance",
	立即兑换: "Redeem Now",
	长按图片保存: "Long Press the Picture to Save",
	分享推广: "Share Promotion",
	保存二维码: "Save QR Code",
	扫一扫来玩TNFT赚取收益: "Scan to purchase TNFT",
	我的邀请码: "My Invitation Code",
	立即分享给好友: "Share this with friends now",
	复制链接: "Copy Link",
	复制邀请码: "Copy the Invitation Code",
	请输入开户姓名: "Please enter the account name",
	账户余额: "Account Balance",
	收入: "Income",
	支出: "Spending",
	联系客服: "Contact Customer Service",
	在线客服: "Online Service",
	客服1: "Customer Service 1",
	客服2: "Customer Service 2",
	打开: "Open",
	我的收藏: "Wishlist",
	申请商家: "Apply for Business",
	确认入驻商家: "Confirm the Settled Business",
	我同意: "I Agree",
	升级请联系客服: "Please Contact Customer Service to Upgrade",
	NFT搜索: "NFT Search",
	请输入搜索关键词: "Please Enter The Search Keyword",
	最新: "Latest",
	最热: "Hottest",
	创作NFT: "Create NFTs",
	NFT文件: "NFT File",
	NFT名称: "NFT Name",
	简介: "Introduction",
	请输入简介: "Please Enter a Profile",
	合集: "Collection",
	点击选择主链: "Click to Select Main Chain",
	立即创建: "Create Now",
	申请须知: "Application Instructions",
	"申请NFT发行方需联系TNFT客服，申请成功，即可创建属于您自己的NFT作品!": "To apply to be an NFT creator, please contact the TNFT Customer Service. If the application is successful, you can create your own NFT art work!",
	历史发行: "History",
	藏品类目: "Collection",
	盲盒类目: "Blind Box",
	支付详情: "Payment Details",
	请输入支付密码: "Please Enter Payment Password",
	预售倒计时: "Pre-Sale Countdown",
	支付倒计时: "Payment Countdown",
	订单号: "Order ID",
	购买类型: "Purchase Type",
	下单时间: "Order Time",
	预售订单: "Pre-Order",
	支付时间: "Payment Time",
	等待支付: "Wait for Payment",
	已失效: "Expired",
	合计: "Total",
	立即支付: "Pay Now",
	NFT详情: "NFT Details",
	NFT信息: "NFT Information",
	预售价格: "Pre-Sale Price",
	购买类型: "Purchase Type",
	预售订单: "Pre-Order",
	开始时间: "Starting Time",
	发行方信息: "Creator Information",
	作品特性: "Features",
	合约地址: "Contract address",
	作品描述: "Description",
	买入: "Buy",
	卖出: "Sell",
	立即下单: "Order Now",
	持有数量: "Number of Holdings",
	我要卖: "Sell",
	升级商家: "Merchant",
	请输入卖出份数: "Please Enter the Number of Copies Sold",
	卖出NFT: "Sell NFT",
	获得金额: "Get Amount",
	出售: "Sale",
	挂单数量: "Quantity",
	拥有者: "Owner",
	最少卖出: "Least Sell",
	我要买: "Buy",
	买入NFT: "Purchase NFTs",
	请输入买入份数: "Please Enter the Number of Purchases",
	需支付: "Payable",
	购买: "Buy",
	最少买入: "Minimum Buy",
	每份: "1",
	确认下单: "Confirm Order",
	"预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "The Pre-Sale is in progress, please wait for the Pre-Sale to end after the order is completed. After the Pre-Sale ends, please pay the amount of the order within 24 hours!",
	暂未对普通会员开放: "No Access for Regular Members",
	限购数量: "Quantity Limit",
	
	数量: "Quantity",
	剩余: "Surplus",
	发布方信息: "Publisher Information",
	挂单: "Pending Order",
	交易订单: "Trade Order",
	当前持有: "Currently Held",
	NFT总份数: "Total number of NFTs",
	选择买入或卖出: "Select to Buy or Sell",
	请输入挂单价格: "Please Enter the Pending Order Price",
	请输入数量: "Please Enter the Quantity",
	最低: "Minimum",
	请输入最低份数: "Please Enter a Minimum Number of Copies",
	最高: "Maximum",
	请输入最高份数: "Please Enter the Maximum Number of Copies",
	交易通知邮箱: "Transaction Notification Mailbox",
	请输入邮箱: "Please Insert your Email",
	类型: "Type",
	价格: "Price",
	立即提交: "Submit Now",
	撤销: "Revoke",
	已撤销: "Revoked",
	已完成: "Completed",
	支付金额: "Payment Amount",
	地址: "Address",
	凭证: "Certificate",
	买入订单: "Buy Order",
	卖出订单: "Sell Order",
	支付货币: "Payment Currency",
	状态: "Status",
	确认下单: "Surplus",
	'预售进行中,下单完成后请6小时内支付给商家!': "The Pre-Sale is in progress, please pay to the merchant within 6 hours after placing the order!",
	参数错误: "Parameter Error",
	该操作需要申请为TNFT商家: "This operation requires to apply as a TNFT merchant",
	碎片化顶级NFT艺术展览尽在TNFT: "Fragmented top NFT art exhibitions are all in TNFT",
	NFT碎片: "NFT Fragments",
	将您持有的NFT分布成碎片: "Distribute the NFT you hold into fragments",
	成为商家: "Become a Merchant",
	成为商家您可以将您持有的NFT拆分: "Become a merchant and split your NFT into fragmentation",
	入驻TNFT商家: "Settled into TNFT Merchants",
	申请须知: "Application Notes",
	请提交区块链应用合法执照或者证件: "Please Submit a Legal License or Valid Certificate of Blockchain Application",
	开始: "Start",
	关于NFT: "About NFT",
	创作中心: "Creation Center",
	合成中心: "Synthesis Center",
	TNFT商家: "Merchant",
	TNFT碎片: "Fragments",
	碎片: "Fragments",
	版税: "Royalties",	
	版税兑换: "Exchange",
	转移NFT: "Transfer NFT",
	转入NFT: "Transfer to NFT",
	版税明细: "Royalty Details",
	联系邮箱: "Contact Email",
	客服邮箱: "Customer Service's Email Address",
	申请成为商家: "Apply to be a Merchant",	
	公司名称: "Company Name",	
	请输入公司: "Please Enter Company Name",
	国家: "Country",
	请输入国家: "Please Enter Country",	
	营业执照: "Business License",	
	法人证件正面: "Front Side of Legal Supporting Document",	
	法人证件反面: "Reverse Side of Legal Supporting Document",	
	请等待审核: "Under Review",	
	请输入正确参数: "Please Enter the Correct Parameters",	
	请上传完整图片: "Please Upload Full Picture",	
	申请商家须知: "Notice for Applying f Merchantship",	
	请提交区块链应用合法执照或者证件: "Please submit the legal license or certificate of blockchain application",	
	我的碎片: "My Pieces",
	持有: "Hold",
	合成: "Synthesis",
	碎片数量不足: "Insufficient number of fragments",
	合成碎片: "Synthetic Fragments",
	'合成碎片不可取消,是否确认合成': "Combining fragments cannot be cancelled, please to confirm the synthesis",
	兑换NFT: "Redeem NFT",
	请输入兑换份数: "Please Enter the Number of Copies",
	需支付版税: "Royalties are Payable",
	兑换: "Exchange",
	点击选择NFT: "Click to select NFT",
	请输入地址: "Please Enter Address",
	转移记录: "Transfer Record",
	NFT转入记录: "NFT Transfer Record",
	NFT转入: "NFT Transfer In",
	请输入名称: "Please Enter a Valid Name",
	'请输入特性,请使用逗号隔开': "Please enter the characteristics, please use commas to separate",
	请输入描述: "Please Enter a Description",
	上传NFT文件: "Upload NFT file",
	上传转入凭证: "Upload transfer Certificate",
	请上传NFT文件: "Please upload the NFT File",
	请输入交易密码: "Please enter the Transaction Password",
	'暂未设置交易密码,请设置交易密码': "No transaction password has been set yet, please set a transaction password",
	地址修改: "Address Modification",
	'扫一扫来玩TNFT,交易0版税,推荐更有版税佣金': "Scan to start TNFT, 0 royalties for transactions, and more royalties for recommendations",
	忘记交易密码: "Royalties",
	重复密码: "Royalties",
	新登录密码: "Royalties",
	重复登录密码: "Royalties",
	查看该作者: "View the Author",
	待确认: "To be Confirmed",
	立即确认: "Confirm Now",
	请等待确认: "Please wait for Confirmation",
	等待确认: "Waiting for Confirmation",
	提示: "Hint",
	'您已确认无误收到款项，是否确认': "You have confirmed that the payment was received without error. Are you certain of this?",
	点击选择NFT文件: "Click to select NFT File",
	请输入拆分份额: "Please Enter the Split Share Amount",
	限购: "Purchase Limit",
	每人限购份额: "Purchase limited per user",
	币种: "Currency",
	点击选择币种: "Click to select Currency",
	请输入价格: "Please enter a Price",
	'倒计时(小时)': "Countdown (Hours)",
	点击选择倒计时: "Click to select Countdown",
	TG聊天链接: "Telegram Chat Link",
	请输入链接: "Please enter Link",
	Line聊天链接: "Line Chat Link",
	WhatsApp聊天链接: "WhatsApp Chat Link",
	制作NFT碎片: "Make NFT fragments",
	发布记录: "Release Record",
	信息: "Information",
	您需要支付: "Payment Required",
	'未收到款?': "Payment Declined",
	'您未收到款,客服将在2小时内处理您的订单,立即申请客服处理!': "Your payment has been rejected, your order is on hold for 2 hours. Please contact Customer Service for more information!",
	联系商家: "Contact Merchant",
	倒计时: "Countdown",
	卖出范围: "Selling Range",
	买入范围: "Buying Range",
	该操作需要申请为TNFT商家: "This feature access requires for applying as a TNFT merchant",
	'申请商家需要持有TNFT Cash Pledge，详情请联系官方客服或邮箱': 'The Applicants need to hold a TNFT Cash Pledge. For more details, please contact Customer Service.',
	质押TNFT: 'Staking TNFT',
	质押记录: 'Pledge Record',
	赎回成功: 'Successful',
	进行中: 'Completed',
	审核中: 'Under Review',
	质押失败: 'Pledge Failed',
	解除质押: 'Cancel Pledge',
	奖励: 'Award',
	创建时间: 'Pledged On',
	结束时间: 'Ends On',
	赎回: 'Claim',
	TNFT名称: 'TNFT Title',
	预售价格: 'Pre-Sale Price',
	质押数量: 'Pledge Quantity',
	请输入数量: 'Please Enter a Quantity',
	全部: 'All',
	可用数量: 'Quantity Available',
	'碎片奖励(天)': 'Fragment Reward (Days)',
	备注: 'Remark',
	质押时间: 'Pledge Time',
	计息时间: 'Interest Accrual Period',
	赎回时间: 'Redemption Time',
	立即提交: 'Submit Now',
	'时间(天)': 'Time(Day)',
	质押确认: 'Pledge Confirmation',
	'质押您可以获得版税和手续费奖励，质押不可取消，请确认!': 'You can earn royalties and handling fees by staking.. The pledgement can not be cancelled, please confirm!',
	持有: 'Hold',
	质押: 'Pledge',
	价格趋势: 'Price Trend',
	持仓市值: 'Assets Market Value',
	今日盈亏: 'Today’s Revenue',
	总盈亏: 'Total Revenue',
	持仓成本: 'Holding Amount',
	碎片数量: 'Number of Fragments',
	质押碎片: 'Pledge Quantity',
	质押收入: 'Successful Pledges',
	名称: 'Title',
	实时价: 'Price',
	市值: 'Market Value',
	质押: 'Pledge',
	持仓: 'Assets',
	我的持仓: 'My Assets',
	查看: 'More',
	实名认证: 'Verified',
	请等待实名审核: 'Please be advised that the review is currently being conducted.',
	确认: 'Confirm',
	注意事项: 'Precautions',
	'需持有TNFT Handling fee数量': 'The amount of TNFT Handling fee required to be held',
	上传身份证明: 'Upload proof of Identity',
	上传持有凭证: 'Upload Legal Certificate',
	历史: 'History',
	操作: 'Operate',
	
	交易: ' Trade',
	一键买入: 'Quick Buy',
	一键卖出: 'Quick Sell',
	最低回购价: 'Repurchase Price',
	一键买卖: 'One-Click Transaction',
	买入价格: 'Buying Price',
	预估回购价格: 'Estimated Buyback Price',
	回购价格比例: 'Repurchase Price Ratio',
	买入数量: 'Buy Quantity',
	请输入买入数量: 'Please enter the purchase quantity',
	卖出数量: 'Sell Quantity',
	请输入卖出数量: 'Please enter the selling quantity',
	回购价格: 'Buyback Price',
	数量不足: 'Insufficient Quantity',
	信用分: 'Credit Score',
	使用版税兑换: 'Use Royalty Redemption',
	'使用TNFT Royalties兑换': 'Redeem using TNFT Royalties',
	'需支付版税/碎片': 'Royalties/Fragments Pending Payment',
	'OTC只支持USDT(ERC)选项': 'OTC only supports USDT(ERC) Option',
	进行中: 'In Progress',
	请联系右上角与商家沟通交易: 'Please select the upper right corner to contact the merchant directly to discuss the most suitable transaction method.',
	订单已完成: 'Order Completed',
	订单已取消: 'Order Canceled',
	交易信息: 'Trading Information',
	单价: 'Unit Price',
	购买数量: 'Purchase Quantity',
	支付金额: 'Payment Amount',
	商户名称: 'Business Name',
	订单ID: 'Order ID',
	时间: 'Time',
	类型: 'Type',
	转入地址: 'Transfer to Address',
	进行中: 'In Progress',
	已取消: 'Cancelled',
	已完成: 'Completed',
	OTC交易: 'OTC trading',
	'TNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'TNFT OTC transactions are connected to mainstream exchange merchants, providing you with convenient legal currency transaction needs.',
	OTC钱包: 'OTC Wallet',
	卖出到OTC余额: 'Sell to OTC Balance',
	OTC余额支付: 'OTC Balance Payment',
	OTC余额: 'OTC Balance',
	C2C转账: 'C2C Transfer',
	C2C需要您手动转账: 'C2C requires manual transfer of funds.',
	确认支付: 'C2C C2C requires manual transfer of funds.',
	'OTC-Pay将使用您的OTC余额支付，请确认！': 'Please be advised that OTC-Pay will use your OTC balance to pay, please confirm once!',
	OTC快捷交易: 'OTC Quick Transaction',
	快捷交易能够让您以当前OTC市场中的最优价格成交: 'Quick trading allocates you to complete transactions at the best price in the current OTC market',
	购买: 'Buy',
	出售: 'Sell',
	我要支付: 'I want to pay',
	我将收到: 'I will receive',
	参考价格: 'Proposed Price',
	如何使用OTC: 'How to use OTC',
	OTC记录: 'OTC Records',
	交易数量: 'Number of Transactions',
	请先KYC再进行OTC操作: 'Please conduct KYC before performing OTC operations',
	上传身份证正面: 'Upload the front of your ID card',
	上传身份证反面: 'Upload the reverse side of yiyr ID card',
	请上传身份证正面: 'Please upload the front side of your ID card',
	请上传身份证反面: 'Please upload the reverse side of your ID card',
	交易对象ID: 'Transaction Object ID',
	升级商家: 'Upgrade Merchant',
	请联系客服: 'Please contact Customer Service',
	确认入驻商家: 'Confirm Merchants settled in',
	我同意: 'Agree',
	成为发行方: 'Become a Creator',
	成为发行方您可以发行属于您自己的NFT: 'Become a creator and make your unique NFTs',
	NFT发行方: 'NFT Creator',
	'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': 'To apply to become an NFT creator, corresponding legal qualifications for blockchain required. For more details, please contact Customer Service',
	'您的OTC钱包需持有': 'Your OTC wallet needs to hold',
	
	指数交易: 'Index Trading',
	未成交: 'Unsold',
	进行中: 'In Progress',
	已完成: 'Completed',
	强制平仓: 'Forced Liquidation',
	撤单: 'Cancel Order',
	当前委托: 'Current',
	历史委托: 'Records',
	买入: 'BUY',
	卖出: 'SELL',
	倍杠杆: 'Leverage',
	保证金: 'Margin',
	开仓价: 'Opening Price',
	当前价: 'Current Price',
	持仓数量: 'Quantity',
	预计盈亏: 'Expected Profit and Loss',
	创建时间: 'Date of Creation',
	止损价: 'Stop Loss',
	止盈价: 'Take Profit',
	手续费: 'Handling Fee',
	添加保证金: 'Add Margin',
	平仓: 'Close the Position',
	立即下单: 'Create Order',
	平仓价格: 'Closing Price',
	平仓时间: 'Time of Completion',
	买涨: 'BUY UP',
	买跌: 'BUY THE DIP',
	OTC余额: 'OTC Balance',
	杠杆: 'Lever',
	'NFT-GI预估数量': 'NFT-GI Estimated Quantity',
	预估手续费: 'Estimated Handling Fee',
	'买入/开多': 'BUY / Open Long',
	'卖出/开空': 'SELL / Going Short',
	设置价格: 'Set an Amount',
	请输入价格: 'Enter an Amount',
	请输入增加的保证金: 'Enter the Additional Margin',
	请输入买入USDT金额: 'Enter the USDT amount to purchase',
	最低增加保证金为10USDT: 'The minimum additional margin is 10USDT',
	订单信息: 'Order Information',
	'您是否立即撤单，撤单不可恢复，请确认!': 'Do you want to cancel the order now? Cancellation is irreversible. Please confirm!',
	'立即按照当前价格平仓，请确认!': 'Closing the position at the current price, please confirm!',
	'最小交易金额为100USDT': 'The minimum amount is 100 USDT',
	'盈亏': 'Profit and Loss',
	 NFT指数: 'NFT Index',
	 交易明细: 'Transaction Details',
	 请输入正确的登录密码: 'Please enter the correct login password',
	 两次密码不一致: 'The two passwords do not match',
	 请输入正确的交易密码: 'Please enter the correct transaction password',
	 NFT借贷: 'NFT Lending',
	 借贷: 'Borrowing',
	 借贷入口: 'Loan Entry',
	 还贷入口: 'Loan repayment entrance',
	 借款申请: 'Loan Application',
	 借款金额: 'Loan amount',
	 '请输入申请金额(USDT)': 'Please enter the application amount (USDT)',
	 账户: 'Account',
	 申请将获取您的账户信息: 'The application will obtain your account information',
	 立即申请: 'Apply immediately',
	 借款记录: 'Loan record',
	 借款金额: 'Loan amount',
	 创建时间: 'Creation time',
	 完成: 'Completed',
	 进行中: 'in progress',
	 联系客服获取借款合同: 'Contact customer service to obtain a loan agreement',
	 OTC还贷: 'OTC loan repayment',
	 '该交易将为您购买的USDT,直接还款': 'This transaction will directly repay the USDT you purchased',
	 我要还款: 'I want to repay',
	 
	 
}