export default {
    邮箱: "邮箱",
	手机号码: "手机号码",
	密码: "密码",
	登录: "登录",
	还没账户: "还没账户",
	忘记密码: "忘记密码",
	立即注册: "立即注册",
	TNFT账户登录: "TNFT账户登录",
	请输入邮箱: "请输入邮箱",
	邮箱格式错误: "邮箱格式错误",
	请输入手机号码: "请输入手机号码",
	请输入密码: "请输入密码",
	请选择: "请选择",
	TNFT注册: "TNFT注册",
	验证码: "验证码",
	推荐码: "推荐码",
	确定: "确定",
	获取验证码: "获取验证码",
	邮箱格式错误: "邮箱格式错误",
	请输入手机号码: "请输入手机号码",
	请输入邮箱: "请输入邮箱",
	请输入正确的邮箱: "请输入正确的邮箱",
	请输入密码: "请输入密码",
	登录密码只能为6至20位数字或字母: "登录密码只能为6至20位数字或字母",
	重置密码: "重置密码",
	新密码: "新密码",
	
	预售中: "预售中",
	交易列表: "交易列表",
	收藏品: "收藏品",
	限量: "限量",
	份: "份",
	用户指南: "用户指南",
	什么是NFT: "什么是NFT",
	首页: "首页",
	发现: "发现",
	藏宝室: "藏宝室",
	我的: "我的",
	搜索您要的精彩: "搜索您要的精彩",
	我的收藏: "我的收藏",
	环球猎手顶级NFT艺术展览尽在TNFT: "环球猎手顶级NFT艺术展览尽在TNFT",
	成为发行方: "成为发行方",
	成为发行方您可以在TNFT发行NFT: "成为发行方您可以在TNFT发行NFT",
	升级商家: "升级商家",
	成为商家您可以挂交易订单: "成为商家您可以挂交易订单",
	公告头条: "公告头条",
	关于发行: "关于发行",
	拍卖中心: "拍卖中心",
	铸造中心: "铸造中心",
	发行机构: "发行机构",
	创作NFT: "创作NFT",
	"加入NFT发行方，您可以创建属于自己的NFT": "加入NFT发行方，您可以创建属于自己的NFT",
	入驻NFT发行: "入驻NFT发行",
	开始: "开始",
	订单记录: "订单记录",
	全部: "全部",
	进行中: "进行中",
	已失效: "已失效",
	已完成: "已完成",
	预售倒计时: "预售倒计时",
	支付倒计时: "支付倒计时",
	份额: "份额",
	需支付: "需支付",
	立即支付: "立即支付",
	立即查看: "立即查看",
	订单号: "订单号",
	升级商家: "升级商家",
	成为发行方: "成为发行方",
	"请输入USDT(TRC20)地址": "请输入USDT(TRC20)地址",
	请输入USDT地址: "请输入USDT地址",
	确认: "确认",
	取消: "取消",
	信息: "信息",
	我的收藏: "我的收藏",
	我的NFT: "我的NFT",
	余额: "余额",
	充值: "充值",
	提现: "提现",
	我的资产: "我的资产",
	团队列表: "团队列表",
	邀请会员: "邀请会员",
	账户绑定: "账户绑定",
	账户明细: "账户明细",
	联系客服: "联系客服",
	退出登录: "退出登录",
	编辑资料: "编辑资料",
	头像: "头像",
	昵称: "昵称",
	账号: "账号",
	推荐码: "推荐码",
	安全设置: "安全设置",
	交易密码: "交易密码",
	"昵称长度必须为5-15个字符": "昵称长度必须为5-15个字符",
	提交: "提交",
	登录密码: "登录密码",
	新交易密码: "新交易密码",
	消息列表: "消息列表",
	暂无信息: "暂无信息",
	充值记录: "充值记录",
	没有更多了: "没有更多了",
	审核通过: "审核通过",
	审核中: "审核中",
	驳回: "驳回",
	提现: "提现",
	开户姓名: "开户姓名",
	提现金额: "提现金额",
	输入金额: "输入金额",
	最大可提金额: "最大可提金额",
	提现记录: "提现记录",
	团队列表: "团队列表",
	注册时间: "注册时间",
	我的资产: "我的资产",
	资产列表: "资产列表",
	Swap货币兑换: "Swap货币兑换",
	货币兑换: "货币兑换",
	兑换金额: "兑换金额",
	您最多可兑换: "您最多可兑换",
	余额不足: "余额不足",
	立即兑换: "立即兑换",
	长按图片保存: "长按图片保存",
	分享推广: "分享推广",
	保存二维码: "保存二维码",
	扫一扫来玩TNFT赚取收益: "扫一扫来玩TNFT赚取收益",
	我的邀请码: "我的邀请码",
	立即分享给好友: "立即分享给好友",
	复制链接: "复制链接",
	复制邀请码: "复制邀请码",
	请输入开户姓名: "请输入开户姓名",
	联系客服: "联系客服",
	在线客服: "在线客服",
	客服1: "客服1",
	客服2: "客服2",
	打开: "打开",
	我的收藏: "我的收藏",
	申请商家: "申请商家",
	确认入驻商家: "确认入驻商家",
	我同意: "我同意",
	升级请联系客服: "升级请联系客服",
	NFT搜索: "NFT搜索",
	请输入搜索关键词: "请输入搜索关键词",
	最新: "最新",
	最热: "最热",
	创作NFT: "创作NFT",
	NFT文件: "NFT文件",
	NFT名称: "NFT名称",
	简介: "简介",
	请输入简介: "请输入简介",
	合集: "合集",
	点击选择主链: "点击选择主链",
	立即创建: "立即创建",
	申请须知: "申请须知",
	"申请NFT发行方需联系TNFT客服，申请成功，即可创建属于您自己的NFT作品!": "申请NFT发行方需联系TNFT客服，申请成功，即可创建属于您自己的NFT作品!",
	历史发行: "历史发行",
	藏品类目: "藏品类目",
	盲盒类目: "盲盒类目",
	支付详情: "支付详情",
	请输入支付密码: "请输入支付密码",
	预售倒计时: "预售倒计时",
	支付倒计时: "支付倒计时",
	余额: "余额",
	订单号: "订单号",
	购买类型: "购买类型",
	下单时间: "下单时间",
	预售订单: "预售订单",
	支付时间: "支付时间",
	等待支付: "等待支付",
	已失效: "已失效",
	合计: "合计",
	立即支付: "立即支付",
	NFT详情: "NFT详情",
	NFT信息: "NFT信息",
	预售价格: "预售价格",
	购买类型: "购买类型",
	预售订单: "预售订单",
	开始时间: "开始时间",
	发行方信息: "发行方信息",
	作品特性: "作品特性",
	合约地址: "合约地址",
	作品描述: "作品描述",
	买入: "买入",
	卖出: "卖出",
	立即下单: "立即下单",
	持有数量: "持有数量",
	我要卖: "我要卖",
	升级商家: "升级商家",
	请输入卖出份数: "请输入卖出份数",
	卖出NFT: "卖出NFT",
	获得金额: "获得金额",
	出售: "出售",
	挂单数量: "挂单数量",
	拥有者: "拥有者",
	最少卖出: "最少卖出",
	我要买: "我要买",
	买入NFT: "买入NFT",
	请输入买入份数: "请输入买入份数",
	需支付: "需支付",
	购买: "购买",
	最少买入: "最少买入",
	每份: "",
	确认下单: "确认下单",
	"预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!",
	暂未对普通会员开放: "暂未对普通会员开放",
	'预售进行中,下单完成后请6小时内支付给商家!': '预售进行中,下单完成后请6小时内支付给商家!',
	限购数量: "限购数量",
	'申请商家需要持有TNFT Cash pledge，详情请联系官方客服或邮箱': '申請商家需持有TNFT Cash pledge，詳情請聯絡官方客服或邮箱',
	质押TNFT: '質押TNFT',
	质押记录: '質押記錄',
	赎回成功: '贖回成功',
	进行中: '進行中',
	审核中: '審核中',
	质押失败: '質押失敗',
	解除质押: '解除質押',
	奖励: '獎勵',
	创建时间: '創建時間',
	结束时间: '結束時間',
	赎回: '贖回',
	TNFT名称: 'TNFT名稱',
	预售价格: '預售價格',
	质押数量: '質押數量',
	请输入数量: '請輸入數量',
	全部: '全部',
	可用数量: '可用數量',
	'碎片奖励(天)': '碎片獎勵(天)',
	备注: '備註',
	质押时间: '質押時間',
	计息时间: '計息時間',
	赎回时间: '贖回時間',
	立即提交: '立即提交',
	'时间(天)': '時間(天)',
	质押确认: '質押確認',
	'质押您可以获得版税和手续费奖励，质押不可取消，请确认!': '質押您可以獲得版稅和手續費獎勵，質押不可取消，請確認!',
	持有: '持有',
	质押: '質押',
	价格趋势: '價格趨勢',
	持仓市值: '持倉市值',
	今日盈亏: '今日盈虧',
	总盈亏: '總盈虧',
	持仓成本: '持倉成本',
	碎片数量: '碎片數量',
	质押碎片: '質押碎片',
	质押收入: '質押收入',
	名称: '名稱',
	实时价: '即時價',
	市值: '市值',
	质押: '質押',
	持仓: '持倉',
	我的持仓: '我的持倉',
	查看: '查看',
	实名认证: '实名认证',
	请等待实名审核: '请等待实名审核',
	确认: '确认',
	注意事项: '注意事项',
	'需持有TNFT Handling fee数量': '需持有TNFT Handling fee数量',
	上传身份证明: '上传身份证明',
	上传持有凭证: '上传持有凭证',
	历史: '历史',
	操作: '操作',
	
	交易: ' 交易',
	一键买入: '一键买入',
	一键卖出: '一键卖出',
	最低回购价: '最低回购价',
	一键买卖: '一键买卖',
	买入价格: '买入价格',
	预估回购价格: '预估回购价格',
	回购价格比例: '回购价格比例',
	买入数量: '买入数量',
	请输入买入数量: '请输入买入数量',
	卖出数量: '卖出数量',
	请输入卖出数量: '请输入卖出数量',
	回购价格: '回购价格',
	数量不足: '数量不足',
	信用分: '信用分',
	使用版税兑换: '使用版税兑换',
	'使用TNFT Royalties兑换': '使用TNFT Royalties兑换',
	'需支付版税/碎片': '需支付版税/碎片',
	'OTC只支持USDT(ERC)选项': 'OTC只支持USDT(ERC)选项',
	进行中: '进行中',
	请联系右上角与商家沟通交易: '请联系右上角与商家沟通交易',
	订单已完成: '订单已完成',
	订单已取消: '订单已取消',
	交易信息: '交易信息',
	单价: '单价',
	购买数量: '购买数量',
	支付金额: '支付金额',
	商户名称: '商户名称',
	订单ID: '订单ID',
	时间: '时间',
	类型: '类型',
	转入地址: '转入地址',
	进行中: '进行中',
	已取消: '已取消',
	已完成: '已完成',
	OTC交易: 'OTC交易',
	'TNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'TNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。',
	OTC钱包: 'OTC钱包',
	卖出到OTC余额: '卖出到OTC余额',
	OTC余额支付: 'OTC余额支付',
	OTC余额: 'OTC余额',
	C2C转账: 'C2C转账',
	C2C需要您手动转账: 'C2C需要您手动转账',
	确认支付: '确认支付',
	'OTC-Pay将使用您的OTC余额支付，请确认！': 'OTC-Pay将使用您的OTC余额支付，请确认！',
	OTC快捷交易: 'OTC快捷交易',
	快捷交易能够让您以当前OTC市场中的最优价格成交: '快捷交易能够让您以当前OTC市场中的最优价格成交',
	购买: '购买',
	出售: '出售',
	我要支付: '我要支付',
	我将收到: '我将收到',
	参考价格: '参考价格',
	如何使用OTC: '如何使用OTC',
	OTC记录: 'OTC记录',
	交易数量: '交易数量',
	请先KYC再进行OTC操作: '请先KYC再进行OTC操作',
	上传身份证正面: '上传身份证正面',
	上传身份证反面: '上传身份证反面',
	请上传身份证正面: '请上传身份证正面',
	请上传身份证反面: '请上传身份证反面',
	交易对象ID: '交易对象ID',
	升级商家: '升级商家',
	请联系客服: '请联系客服',
	确认入驻商家: '确认入驻商家',
	我同意: '我同意',
	成为发行方: '成为发行方',
	成为发行方您可以发行属于您自己的NFT: '成为发行方您可以发行属于您自己的NFT',
	NFT发行方: 'NFT发行方',
	'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': '申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱',
	'您的OTC钱包需持有': '您的OTC钱包需持有',
	
	指数交易: '指数交易',
	未成交: '未成交',
	进行中: '进行中',
	已完成: '已完成',
	强制平仓: '强制平仓',
	撤单: '撤单',
	当前委托: '当前委托',
	历史委托: '历史委托',
	买入: '买入',
	卖出: '卖出',
	倍杠杆: '倍杠杆',
	保证金: '保证金',
	开仓价: '开仓价',
	当前价: '当前价',
	持仓数量: '持仓数量',
	预计盈亏: '预计盈亏',
	创建时间: '创建时间',
	止损价: '止损价',
	止盈价: '止盈价',
	手续费: '手续费',
	添加保证金: '添加保证金',
	平仓: '平仓',
	立即下单: '立即下单',
	平仓价格: '平仓价格',
	平仓时间: '平仓时间',
	买涨: '买涨',
	买跌: '买跌',
	OTC余额: 'OTC余额',
	杠杆: '杠杆',
	'NFT-GI预估数量': 'NFT-GI预估数量',
	预估手续费: '预估手续费',
	'买入/开多': '买入/开多',
	'卖出/开空': '卖出/开空',
	设置价格: '设置价格',
	请输入价格: '请输入价格',
	请输入增加的保证金: '请输入增加的保证金',
	请输入买入USDT金额: '请输入买入USDT金额',
	最低增加保证金为10USDT: '最低增加保证金为10USDT',
	订单信息: '订单信息',
	'您是否立即撤单，撤单不可恢复，请确认!': '您是否立即撤单，撤单不可恢复，请确认!',
	'立即按照当前价格平仓，请确认!': '立即按照当前价格平仓，请确认!',
	'最小交易金额为100USDT': '最小交易金额为100USDT',
	盈亏: '盈亏',
	NFT指数: 'NFT指数',
	交易明细: '交易明细',
	请输入正确的登录密码: '请输入正确的登录密码',
	两次密码不一致: '两次密码不一致',
	请输入正确的交易密码: '请输入正确的交易密码',
	NFT借贷: 'NFT借贷',
	借贷: '借贷',
	借贷入口: '借贷入口',
	还贷入口: '还贷入口',
	借款申请: '借款申请',
	借款金额: '借款金额',
	'请输入申请金额(USDT)': '请输入申请金额(USDT)',
	账户: '账户',
	申请将获取您的账户信息: '请输入邮箱',
	立即申请: '立即申请',
	借款记录: '借款记录',
	借款金额: '借款金额',
	创建时间: '创建时间',
	完成: '完成',
	进行中: '进行中',
	联系客服获取借款合同: '联系客服获取借款合同',
	OTC还贷: 'OTC还贷',
	'该交易将为您购买的USDT,直接还款': '该交易将为您购买的USDT,直接还款',
	我要还款: '我要还款',
}